import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/packages/swarm-docs/src/components/layout-mdx.js";
import { Menu, MenuList, MenuButton, MenuItem, MenuLink } from '@meetup/swarm-components';
import { Edit } from '@meetup/swarm-icons/lib/components/line';
import PropsTable from '../../components/propstable';
import Example from '../../components/examples/dropdown';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Dropdown`}</h1>
    <hr></hr>
    <p>{`An accessible set of components used to compose our dropdown menus.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { 
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
  MenuLink
  } from '@meetup/swarm-components';
`}</code></pre>
    <Example mdxType="Example" />
    <p>{`Manage Group Example`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-.jsx"
      }}>{`<Menu>
  <MenuButton icon="arrow-down" right>
    Manage Group
  </MenuButton>
  <MenuList focusableChildrenTypes={[MenuItem]}>
    <MenuItem onSelect={() => alert("Download")}>Contact Group Members</MenuItem>
    <MenuItem onSelect={() => alert("Copy")}><Edit />Edit Group Settings</MenuItem>
    <MenuItem onSelect={() => alert("Mark as Draft")}>Mark as Draft</MenuItem>
    <MenuLink
      as="a"
      href="https://meetup.com"
    >Meetup</MenuLink>
    <MenuItem onSelect={() => alert("Delete")}>Delete</MenuItem>
  </MenuList>
</Menu>
`}</code></pre>
    <p>{`Create Event Example`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-.jsx"
      }}>{`<Menu>
  <MenuButton primary icon="arrow-down" right>
    Create Event
  </MenuButton>
  <MenuList focusableChildrenTypes={[MenuItem]}>
    <MenuItem onSelect={() => alert("Download")}>Create New Event</MenuItem>
    <MenuItem onSelect={() => alert("Copy")}>Book as Space</MenuItem>
    <MenuItem onSelect={() => alert("Mark as Draft")}><Edit />Mark as Draft</MenuItem>
    <MenuLink
      as="a"
      href="https://meetup.com"
    >Meetup</MenuLink>
    <MenuItem onSelect={() => alert("Delete")}>Delete</MenuItem>
  </MenuList>
</Menu>
`}</code></pre>
    <h2>{`Props`}</h2>
    <p>{`Dropdowns are composed of five components, their props are listed below:`}</p>
    <br />
    <PropsTable propMetaData={Menu.__docgenInfo} mdxType="PropsTable" />
    <PropsTable propMetaData={MenuList.__docgenInfo} mdxType="PropsTable" />
    <PropsTable propMetaData={MenuButton.__docgenInfo} mdxType="PropsTable" />
    <PropsTable propMetaData={MenuItem.__docgenInfo} mdxType="PropsTable" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      